import {
    BarChartOutlined,
    DotChartOutlined,
    PieChartOutlined,
} from "@ant-design/icons";
import { KEYS } from "data/sectionKeys";
import { CenteredSection, Section } from "elements";
import AppGallery from "elements/AppGallery";
import Icons from "elements/Icons";
import { useGetText } from "tools/lang";
import styles from "./style.module.scss";

const ICONS = [
    <PieChartOutlined />,
    <BarChartOutlined />,
    <DotChartOutlined />,
];

const StarcatPanel: React.FC = () => {
    const { getText } = useGetText();

    return (
        <>
            <Section
                primary
                name={KEYS.starcat}
                title={getText("starcatTitle")}
            >
                <CenteredSection>
                    <Icons icons={ICONS} />
                    <div
                        className={styles.description}
                        dangerouslySetInnerHTML={{
                            __html: getText("starcatDescription"),
                        }}
                    />
                </CenteredSection>
            </Section>

            <div>
                <AppGallery />
            </div>
        </>
    );
};

export default StarcatPanel;
