import { Card } from "antd";
import styles from "./style.module.scss";

type Props = {
    title: string;
    description: string;
    image: string;
};

const PersonCard: React.FC<Props> = ({ title, description, image }) => (
    <Card cover={<img alt={title} src={image} />} className={styles.image}>
        <Card.Meta
            className={styles.text}
            title={<span dangerouslySetInnerHTML={{ __html: title }} />}
            description={
                <span dangerouslySetInnerHTML={{ __html: description }} />
            }
        />
    </Card>
);

export default PersonCard;
