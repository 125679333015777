import { KEYS } from "data/sectionKeys";
import { CenteredSection, Section } from "elements";
import { useGetText } from "tools/lang";
import styles from "./style.module.scss";
import {
    AreaChartOutlined,
    DiffOutlined,
    ScissorOutlined,
} from "@ant-design/icons";
import Icons from "elements/Icons";

const ICONS = [<AreaChartOutlined />, <DiffOutlined />, <ScissorOutlined />];

const ZeroPanel: React.FC = () => {
    const { getText } = useGetText();

    return (
        <Section
            primary
            className={styles.wrapper}
            name={KEYS.zero}
            title={getText("zeroTitle")}
        >
            <CenteredSection>
                <Icons icons={ICONS} />
                <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                        __html: getText("zeroDescription"),
                    }}
                />
            </CenteredSection>
        </Section>
    );
};
export default ZeroPanel;
