import { Affix, Layout } from "antd";
import { AboutUs, StarcatPanel } from "sections";
import ContactPanel from "sections/ContactPanel";
import HeaderContent from "sections/HeaderContent";
import ZeroPanel from "sections/ZeroPanel";
import WelcomePanel from "sections/WelcomePanel";

const { Header, Content } = Layout;

const MainPage = () => (
    <>
        <Affix offsetTop={0}>
            <Header>
                <HeaderContent />
            </Header>
        </Affix>

        <WelcomePanel />
        <Content>
            <AboutUs />
            <StarcatPanel />
            <ZeroPanel />
            <ContactPanel />
        </Content>
    </>
);

export default MainPage;
