import { LANGUAGES } from "consts";
import { LanguageContext } from "contexts";
import pl from "data/pl";
import fr from "data/fr";
import { useContext } from "react";

type Text = typeof pl | typeof fr;

const TEXTS_BY_LANGS = new Map([
    [LANGUAGES.PL, pl],
    [LANGUAGES.FR, fr],
]);

const getTextsByLanguage = (lang: LANGUAGES) => TEXTS_BY_LANGS.get(lang)!!;

export const getTextByLanguage = (lang: LANGUAGES, key: keyof Text) => {
    const texts = getTextsByLanguage(lang);
    return texts[key];
};

export const useGetText = () => {
    const { lang } = useContext(LanguageContext);

    const getText = (key: keyof Text) => {
        const texts = getTextsByLanguage(lang);
        return texts[key];
    };

    return { getText };
};
