import { CenteredSection, PersonsGallery, Section } from "elements";
import { useGetText } from "tools/lang";
import styles from "./style.module.scss";
import { KEYS } from "data/sectionKeys";
import { Link } from "react-scroll";
import { buildLink } from "sections/HeaderContent";

const Text: React.FC = () => {
    const { getText } = useGetText();

    return (
        <div>
            <div>
                <span
                    dangerouslySetInnerHTML={{
                        __html: getText("aboutUsDescription2_1"),
                    }}
                />{" "}
                <Link to={buildLink(KEYS.starcat)} smooth>
                    {getText("starcatTitle")}
                </Link>
                .
            </div>
            <div>
                <span
                    dangerouslySetInnerHTML={{
                        __html: getText("aboutUsDescription2_2"),
                    }}
                />{" "}
                <Link to={buildLink(KEYS.zero)} smooth>
                    {getText("zeroTitle")}
                </Link>
                .
            </div>
        </div>
    );
};

const AboutUs: React.FC = () => {
    const { getText } = useGetText();

    return (
        <Section name={KEYS.aboutUs} title={getText("aboutUsTitle")}>
            <CenteredSection>
                <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                        __html: getText("aboutUsDescription"),
                    }}
                />

                <PersonsGallery />

                <div className={styles.description}>
                    <Text />
                </div>
            </CenteredSection>
        </Section>
    );
};

export default AboutUs;
