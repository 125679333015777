const texts = {
    title: "Aspekty FR",
    welcomePanel: "Aspekty FR",
    welcomePanelSubtitle: `Badaj różne <strong>ASPEKTY</strong> wypowiedzi.<br />Skorzystaj z naszych narzędzi do analizy tekstu.<br />Oszczędzaj czas na raportowaniu badań marketingowych i sporządzaniu dokumentacji kadrowej`,

    aboutUsTitle: "O nas",
    aboutUsDescription: `Ewa Gwiazdecka i Anna Kot - dawno temu poznałyśmy się na treningach karatedo doshinkan.<br />Ewa  - miała dość instytucjonalnych ograniczeń, nie odnajdywała się na uniwersytecie i chciała realizować własne projekty badawcze.<br />Ania - zniechęcona tworzeniem abstrakcyjnych umów, chciała swoje prawnicze doświadczenie zastosować do własnego, konkretnego biznesu.`,
    aboutUsDescription2_1: `Nasza wspólna biznesowa przygoda zaczęła się od zamówienia z Francji na polską wersję aplikacji do spotkań interaktywnych i ankiet on-line. Wkrótce zaczęły przychodzić zlecenia od dużych korporacji: zadania z lingwistyki komputerowej oraz różne projekty wielojęzyczne, które wymagały zaangażowania wielu lingwistów.
        <br />
        Spółka rozwijała się i postawiliśmy na własne narzędzia. Zaczęłyśmy współpracę z programistami i językoznawcami z różnych ośrodków badawczych.
        <br />
        Z pasji Ewy do języka powstał Z pasji Ewy do języka powstał`,
    aboutUsDescription2_2: `We współpracy z lingwistami największą zmorą okazały się rachunki. Aby uprościć procedury prawno-księgowe, Ania wpadła na pomysł aplikacji:`,

    personTitle_1: "Ewa Gwiazdecka,<br />językoznawczyni",
    personDescription_1: `Zajmuje się lingwistyką komputerową. Obroniła doktorat na Uniwersytecie Paryż-Sorbona. Wykładała językoznawstwo na Uniwersytecie Warszawskim. Jako absolwentka filozofii wykładała również logikę i metodologię nauk na Uniwersytecie Południowej Afryki (UNISA). Obecnie studentka SGGW na Wydziale Ogrodniczym. Pasjonatka podróży, tańca afrykańskiego i musu czekoladowego.`,
    personTitle_2: "Anna Kot,<br />radca prawny",
    personDescription_2: `Absolwentka Wydziału Prawa i Administracji Uniwersytetu Warszawskiego. Po latach pracy w korporacji Miller, Canfield, Paddock & Stone w 2005 roku otworzyła własną praktykę. Specjalizuje się w prawie własności intelektualnej i nowych technologii. Wielbicielka kotów, a w szczególności trzech, które ją wybrały. Kocha spacery po łąkach i lasach. Uwielbia pływanie wpław i na wszelkich jednostkach pływających bez silnika.`,

    starcatTitle: "StarCat",
    starcatDescription: `
        <p><strong>Automatyczna, jakościowa analiza postów internetowych lub Twoich danych w przejrzystym raporcie</strong></p>
        <p>Internet czasem wygląda jak wielki śmietnik. Wbijasz hasło w wyszukiwarkę, a tylko mała część wyników do czegoś się nadaje. Wbijasz więc nowe i nowe wyrazy, żeby trafić na to, czego szukasz… Ale szukanie i porządkowanie na własną rękę bywa naprawdę męczące.</p>
        <p>Nad tym chaosem pomoże Ci zapanować <strong>StarCat</strong>.</p>
        <p><strong>StarCat</strong> jest <i>trochę innym</i> narzędziem do badania kategorii, marki, produktu lub wizerunku. Opiera się na regułach przetwarzania języka i jest naszym autorskim rozwiązaniem.</p>
        <p><strong>StarCat</strong> dostarczy Ci usystematyzowaną wiedzę, dzięki której z wielu wypowiedzi dowiesz się, co ludzi trapi, co ich kręci, jakie są trendy i mody. Przekonasz się, jakie cechy Twojego produktu, usługi są ważne dla konsumentów.<p/>
        <p>
            <strong>StarCat</strong> z ogromnej ilości danych tekstowych wydobywa <strong>wątki tematyczne</strong> oraz przypisane tym wątkom <strong>opinie</strong>, które klasyfikuje ze względu na <strong>wydźwięk</strong>.<br/>
            Ale podział na opinie <i>pozytywne, negatywne i neutralne</i> to nie wszystko: <strong>StarCat</strong> potrafi <strong>uporządkować opinie</strong> pokazując ich natężenie, poda też <strong>określenia wątków</strong>, które nie są ocenne, ale mogą być ważne dla konsumentów.
        </p>
        <p><strong>Wątki tematyczne</strong> to <i>tematy</i> obecne w tekstach, to coś, o czym się właśnie mówi. Czasem frazy nieoczywiste i nieoczekiwane, które mogą Cię zaskoczyć w kontekście marki lub kategorii. </p>
        <p>Możesz zlecić nam zebranie postów z Internetu lub zamówić analizę własnych treści.</p>
        <p>Zamów intuicyjny, klikalny raport <strong>StarCat</strong>, raport skrojony na Twoją miarę.</p>
        <p><strong><i>Bo ciekawe jest to, na co Ty nie wpadłbyś sam...</i></strong></p>
    `,

    zeroTitle: "Zer0 RACHUNKÓW",
    zeroDescription: `
        <p>Czy wiesz, że zgodnie z prawem zleceniobiorca nie musi wystawiać papierowych rachunków?</p>
        <p>Zleceniobiorca, a w praktyce biuro księgowe, obsługujące Twoją firmę, nie ma obowiązku wystawiania rachunku w celu potwierdzenia wypłaty wynagrodzenia.</p>
        <p>Rachunek papierowy wcale nie musi służyć też do <strong> ewidencjonowania czasu pracy</strong> zleceniobiorcy.</p>
        <p><strong>Zamiast więc płacić biuru rachunkowemu</strong>za wystawianie rachunków, zamiast pilnować zleceniobiorców, żeby je odebrali, podpisali i odesłali, skorzystaj z naszej aplikacji <strong>Zer0 Rachunków</strong>.</p>
        <p>Jak działa <strong> Zer0 Rachunków</strong>?</p>
        <p>Zleceniobiorca po przepracowaniu okresu rozliczeniowego, loguje się do aplikacji za pomocą swojego adresu e-mail i ustanowionego przez siebie hasła, po czym akceptuje wprowadzoną przez Twoją firmę liczbę godzin pracy.</p>
        <p>Na potrzeby <strong>kontroli inspekcji pracy lub urzędu skarbowego, Zer0 Rachunków</strong> wygeneruje raporty, w których znajdą się potwierdzenia czasów pracy wszystkich lub wybranych zleceniobiorców w dowolnym okresie.</p>
        <p>Postaw na oszczędność, ekologię i wygodę!</p>
        <p>Pobierz wzór postanowień umowy-zlecenia, który umożliwi Ci zmianę ewidencjonowania czasu pracy w <strong>Zer0 Rachunków</strong>.</p>
    `,

    contactTitle: "Kontakt",
    contactEmail: "adres e-mail",
    contactMessage: "wiadomość",
    contactAction: "wyślij",
    contactSuccessTitle: "Wiadomość została wysłana",
    contactSuccessDescription: "Wiadomość została wysłana",
    contactErrorTitle: "Ups, coś poszło nie tak...",
    contactErrorDescription: "Coś poszło nie tak...",
    contactEmailFormatError: "Podaj prawidłowy adres email",
    contactEmptyError: "Pole nie może być puste",

    footerAuthor: "Aspekty",
    footerPolitics: "Privacy policy",

    privacyPolicyTitle: "Privacy policy",
    privacyPolicyText: `
        <h4>
            Privacy policy applicable at Aspekty Sp. z o.o., with its principal place of business in Warsaw
            at ul. Pokrzywnicka 5, 04-320 Warszawa (referred hereinafter to as “Aspekty”)
        </h4>
        <p>
            This privacy policy (referred hereinafter to as “Policy”) describes the manner in which we obtain, process and protect personal data of the individuals who visit
            out website, the individuals who decide to email us at the address specified as the contact address on our website and the individuals who order services from us.
            This Policy applies to all individuals visiting our website at <a href="www.aspekty.com">www.aspekty.com</a>.
        </p>
        <h4>The administrator’s data</h4>
        <p>The administrator of your personal data shall be Aspekty Sp. z o.o., with its principal place of business in Warsaw at ul. Pokrzywnicka 5, 04-320 Warszawa</p>
        <h5>Information on cookies</h5>
        <ol>
            <li>We collect and process personal data on our website only using cookies</li>
            <li>
                Cookies are computer data, in particular, text files which are stored in the website end user’s devise and are designated for using the website.
                Cookies usually contain the name of the website they originate from, the time of storing them on the end device and a unique number.
            </li>
            <li>The website operator is the entity which places cookies on the end device of our website’s user and gains access to them.</li>
            <li>
                Cookies are used to monitor the traffic on our website, in particular, they monitor the number of visitors and the paths of traffic.
            </li>
            <li>
                The software for browsing websites (internet browser) usually automatically permits the storage of cookies on the user’s end device.
                Users of our website may change these settings. The internet browser enables removing cookies. It is also possible to automatically block cookies.
                Detailed information concerning this can be found in help or the internet browser’s documentation.
            </li>
            <li>
                Restrictions on the use of cookies may affect some of the functionalities available on our website.
            </li>
            <li>
            Cookies placed on the end device of our website’s user can also be used by entities cooperating with the operator, in particular this concerns the companies:
            Google (Google Inc. based in the USA), Facebook (Facebook Inc. based in the USA) and Twitter (Twitter Inc. based in the USA).
            </li>
        </ol>
        <h5>Cookie management – how you can grant and cancel your consent?</h5>
        <ol>
            <li>
                If a user does not wish to receive cookies, he/she may change the browser settings. We herewith warn that disabling the cookies necessary for authorisation and
                security purposes may cause difficulties and in extreme cases even make it impossible to use our website.
            </li>
            <li>
                Most internet browsers enable accepting or blocking specific cookie files or their types and also blocking or deleting all cookies.
                This depends on a given internet browser. To manage cookie settings, please read the manual of the internet browser you use and follow the instructions.
            </li>
        </ol>
        <h4>How we process and protect personal data provided in an email sent to our email address provided on our website</h4>
        <h5>Consent to data processing</h5>
        <p>
            If you email us at the address <a href="mailto:kontakt@aspekty.com">kontakt@aspekty.com</a>,
            you automatically grant consent to the processing of the personal data provided in the email, including the email address from which your email has been received.
        </p>
        <h5>Purposes of data processing</h5>
        <p>
            We use the personal data obtained this way to respond to your emails, and in case you order a service from us in this manner,
            for the purpose of carrying out the ordered service, invoicing the payment for it and collecting the invoiced payment.
        </p>
        <p>
            We can be obliged on the grounds of mandatory legal regulations to forward your personal data to public authorities.
            In such cases your data will be forwarded without your consent or knowledge and without notifying you of this in advance.
        </p>
        <h5>Legal grounds for data processing</h5>
        <p>
            Your personal data shall be processed on the grounds of article 6, clause 1, letter (a) of the general regulation on personal data protection of 27 April 2016,
            i.e. on the grounds of your consent expressed through emailing us at our address provided in the contact section of our website, and in case we provide a service to you,
            which will mean that you will enter into an agreement on carrying out this service with our firm, your data will also be processed on the grounds
            of article 6, clause 1, letter (b) of the aforementioned regulation.
        </p>
        <h5>Data storage period</h5>
        <p>
            We will store your data as long as you grant consent for this, and in case a service is ordered, i.e. a service agreement is concluded with our firm,
            as long as it will be necessary for the purposes of data archiving, accounting, taxes and other purposes necessary to store data provided in civil
            law agreements concerning the provision of services as provided under mandatory regulations of Polish law.
        </p>
        <h5>Who we disclose data to</h5>
        <p>
            Your personal data can be disclosed to our employees and associates, entities which process them upon order from our firm and, in particular,
            subcontractors who carry out the services provided to you, accounting offices each time they provide services to our firm, entities which provide
            computer systems each time our firm uses them and other entities each time they provide legal and organisational services to our firm as well as
            public authorities or entities authorised to obtain data on the grounds of applicable legal regulations, e.g. courts, prosecution authorities or
            state institutions, when they request so on the basis of adequate legal grounds.
        </p>
        <h5>Data protection means</h5>
        <p>
            Your personal data are protected by means of such solutions as encrypting our email, anti-virus protection and
            controlling our employees access to them and adequate agreements with our associates and subcontractors under
            which they guarantee security of processing of the data made available to them.
        </p>
        <h4>User rights</h4>
        <p>
            MYou have the right to delete or update your personal data you have provided to us. If you believe that the personal data you have provided to us need updating,
            are incomplete, incorrect, improper or no longer current, please notify us at our address <a to="mailto:kontakt@aspekty.com">kontakt@aspekty.com</a>,
            nd we will introduce the necessary changes or delete the incorrect data within the period as provided under legal regulations currently in force.
            You have the right to be informed about which of your personal data we have collected, the right to request us to limit processing of your personal data, the
            right to object against their processing and the right to transfer your data.
            You also have the right to bring a complaint to the President of the Office for Personal Data Protection
            when you recognise that the processing of your personal data violates the regulations of the General Data Protection Regulation of 27 April 2016.
        </p>
        <p>
            You also have the right to request us to stop processing your personal data. However, in the case of withdrawal i.e.
            cancellation of your consent to processing your personal data by us, it may turn out that we will be unable to provide the service ordered by your or
            respond to your emails. Furthermore, cancellation of your consent will not affect the legality of the data processing by us before the cancellation.
        </p>
    `,
};

export default texts;
