import { Form, Input, Button, notification } from "antd";
import { KEYS } from "data/sectionKeys";
import { CenteredSection, Section } from "elements";
import { useGetText } from "tools/lang";
import { ContactMessage, sendMessage } from "../../services/contact";
import styles from "./style.module.scss";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const ContactPanel = () => {
    const { getText } = useGetText();

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: getText("contactEmptyError"),
        types: {
            email: getText("contactEmailFormatError"),
        },
    };

    const onFinish = async (values: ContactMessage) => {
        try {
            await sendMessage(values);
            notification.success({
                message: getText("contactSuccessTitle"),
                description: getText("contactSuccessDescription"),
            });
        } catch (e: any) {
            notification.error({
                message: getText("contactErrorTitle"),
                description: getText("contactErrorDescription"),
            });
        }
    };

    return (
        <Section
            name={KEYS.contact}
            className={styles.wrapper}
            title={getText("contactTitle")}
        >
            <CenteredSection>
                <Form
                    {...layout}
                    name="contact"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                >
                    <Form.Item
                        name="email"
                        label={getText("contactEmail")}
                        rules={[{ type: "email", required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="message"
                        label={getText("contactMessage")}
                        rules={[{ type: "string", required: true }]}
                    >
                        <Input.TextArea rows={12} />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{ ...layout.wrapperCol }}
                        className={styles.contactButton}
                    >
                        <Button type="primary" htmlType="submit" size="large">
                            {getText("contactAction")}
                        </Button>
                    </Form.Item>
                </Form>
            </CenteredSection>
        </Section>
    );
};

export default ContactPanel;
