import { Col, Row } from "antd";
import { PropsWithChildren } from "react";

const CenteredSection: React.FC<PropsWithChildren<{}>> = ({ children }) => (
    <Row justify="center">
        <Col md={23} lg={20} xl={16}>
            {children}
        </Col>
    </Row>
);

export default CenteredSection;
