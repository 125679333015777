import { KEYS } from "data/sectionKeys";
import { Section } from "elements";
import { useGetText } from "tools/lang";

import styles from "./style.module.scss";

const WelcomePanel: React.FC = () => {
    const { getText } = useGetText();

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.header}>{getText("welcomePanel")}</h1>

            <Section name={KEYS.welcome} className={styles.description}>
                <div
                    className={styles.small}
                    dangerouslySetInnerHTML={{
                        __html: getText("welcomePanelSubtitle"),
                    }}
                />
            </Section>
        </div>
    );
};

export default WelcomePanel;
