const CONTACT_API = "https://aspekty.com/mail/mail/send";
// const CONTACT_API = "http://localhost:8080/mail/send";

export type ContactMessage = {
    email: string;
    message: string;
};

export const sendMessage = async (body: ContactMessage) => {
    const result = await fetch(CONTACT_API, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    if (result.status !== 202) {
        throw new Error();
    }
};
