import { Row, Col } from "antd";
import { KEYS } from "data/sectionKeys";
import { PropsWithChildren } from "react";
import { Element } from "react-scroll";

import styles from "./style.module.scss";

type Props = {
    className?: string;
    name: KEYS;
    primary?: boolean;
    title?: string;
};

const Section: React.FC<PropsWithChildren<Props>> = ({
    children,
    className = "",
    name,
    title,
    primary,
}) => (
    <Element name={`#${name}`} id={`#${name}`}>
        <article>
            <div
                className={`${styles.wrapper} ${className} ${
                    primary ? styles.primary : styles.normal
                }`}
            >
                <Row justify="center">
                    <Col span={22} md={22} lg={20} xl={19} xxl={16}>
                        {title && <h2>{title}</h2>}
                        {children}
                    </Col>
                </Row>
            </div>
        </article>
    </Element>
);

export default Section;
