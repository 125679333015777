import styles from "./style.module.scss";
import Logo from "assets/logo_pl.png";

import { Menu, Radio, RadioChangeEvent } from "antd";
import { useGetText } from "tools/lang";

import { Link } from "react-scroll";
import { KEYS } from "data/sectionKeys";
import { useContext } from "react";
import { LanguageContext } from "contexts";
import { LANGUAGES } from "consts";

export const buildLink = (section: KEYS) => `#${section}`;

const HeaderContent = () => {
    const { getText } = useGetText();
    const { changeLanguage, lang } = useContext(LanguageContext);

    const handleChangeLanguage = (e: RadioChangeEvent) =>
        changeLanguage(e.target.value);

    return (
        <div className={styles.headerContentWrapper}>
            <div className={styles.menuWrapper}>
                <Menu selectedKeys={[""]} mode="horizontal">
                    <Menu.Item key={KEYS.aboutUs}>
                        <Link to={buildLink(KEYS.aboutUs)} smooth>
                            {getText("aboutUsTitle")}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={KEYS.starcat}>
                        <Link to={buildLink(KEYS.starcat)} smooth>
                            {getText("starcatTitle")}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={KEYS.zero}>
                        <Link to={buildLink(KEYS.zero)} smooth>
                            {getText("zeroTitle")}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={KEYS.contact}>
                        <Link to={buildLink(KEYS.contact)} smooth>
                            {getText("contactTitle")}
                        </Link>
                    </Menu.Item>
                </Menu>
            </div>

            <div>
                <Radio.Group
                    value={lang}
                    onChange={handleChangeLanguage}
                    size="small"
                >
                    <Radio.Button value={LANGUAGES.PL}>
                        {LANGUAGES.PL}
                    </Radio.Button>
                    <Radio.Button value={LANGUAGES.FR}>
                        {LANGUAGES.FR}
                    </Radio.Button>
                </Radio.Group>
            </div>
            <img className={styles.logo} src={Logo} alt={"logo"}></img>
        </div>
    );
};

export default HeaderContent;
