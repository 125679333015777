import { Layout } from "antd";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AppFooter } from "sections";
import LanguageContextWrapper from "contexts/LanguageContextWrapper";
import { MainPage } from "pages";
import { PATHS } from "data/paths";
import PrivacyPolicy from "pages/PrivacyPolicy";

const App: React.FC = () => (
    <Layout>
        <LanguageContextWrapper>
            <Router>
                <Routes>
                    <Route path={PATHS.MAIN} element={<MainPage />} />
                    <Route path={PATHS.PRIVACY} element={<PrivacyPolicy />} />
                </Routes>

                <Layout.Footer>
                    <AppFooter />
                </Layout.Footer>
            </Router>
        </LanguageContextWrapper>
    </Layout>
);

export default App;
