import { LANGUAGES } from "consts";
import React from "react";

type LangContextState = {
    lang: LANGUAGES;
    changeLanguage: (lang: LANGUAGES) => void;
};

export const DEFAULT_CONTEXT_STATE: LangContextState = {
    lang: LANGUAGES.PL,
    changeLanguage: (lang: LANGUAGES) => {},
};

export const LanguageContext = React.createContext(DEFAULT_CONTEXT_STATE);
