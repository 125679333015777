import styles from "./style.module.scss";

type Props = {
    icons: React.ReactNode[];
    primary?: boolean;
};

const Icons: React.FC<Props> = ({ icons, primary }) => (
    <div className={styles.icons}>
        {icons.map((icon, index) => (
            <div
                key={index}
                className={
                    primary ? styles.iconWrapperPrimary : styles.iconWrapperBg
                }
            >
                {icon}
            </div>
        ))}
    </div>
);

export default Icons;
