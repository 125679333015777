import { Link } from "react-router-dom";

import { PATHS } from "data/paths";
import { useGetText } from "tools/lang";
import styles from "./style.module.scss";

const AppFooter: React.FC = () => {
    const { getText } = useGetText();

    return (
        <div className={styles.wrapper}>
            <div className={styles.footer}>
                <Link to={PATHS.PRIVACY}>{getText("footerPolitics")}</Link>

                <span>
                    {getText("footerAuthor")}
                    {", "}
                    {new Date().getFullYear()}
                </span>
            </div>
        </div>
    );
};

export default AppFooter;
