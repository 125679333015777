import React, { PropsWithChildren, useState } from "react";
import { DEFAULT_CONTEXT_STATE, LanguageContext } from "./LanguageContext";

type Props = {};

const LanguageContextWrapper: React.FC<PropsWithChildren<Props>> = ({
    children,
}) => {
    const [lang, setLang] = useState(DEFAULT_CONTEXT_STATE.lang);

    const value = {
        lang,
        changeLanguage: setLang,
    };

    return (
        <LanguageContext.Provider value={value}>
            <>{children}</>
        </LanguageContext.Provider>
    );
};

export default LanguageContextWrapper;
