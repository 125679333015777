import PersonCard from "./PersonCard";
import styles from "./style.module.scss";

import person1 from "../../assets/ewa.jpg";
import person2 from "../../assets/ania.jpg";
import { useGetText } from "tools/lang";

const PersonsGallery: React.FC = () => {
    const { getText } = useGetText();

    return (
        <div className={styles.gallery}>
            <PersonCard
                title={getText("personTitle_1")}
                description={getText("personDescription_1")}
                image={person1}
            />
            <PersonCard
                title={getText("personTitle_2")}
                description={getText("personDescription_2")}
                image={person2}
            />
        </div>
    );
};

export default PersonsGallery;
