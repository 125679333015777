import { LANGUAGES } from "consts";
import { KEYS } from "data/sectionKeys";
import { CenteredSection, Section } from "elements";
import { getTextByLanguage } from "tools/lang";
import styles from "./styles.module.scss";

const PrivacyPolicy: React.FC = () => {
    return (
        <>
            <Section
                title={getTextByLanguage(LANGUAGES.PL, "privacyPolicyTitle")}
                name={KEYS.privacy}
            >
                <CenteredSection>
                    <div
                        className={styles.wrapper}
                        dangerouslySetInnerHTML={{
                            __html: getTextByLanguage(
                                LANGUAGES.PL,
                                "privacyPolicyText"
                            ),
                        }}
                    />
                </CenteredSection>
            </Section>

            <Section
                title={getTextByLanguage(LANGUAGES.FR, "privacyPolicyTitle")}
                name={KEYS.privacy}
            >
                <CenteredSection>
                    <div
                        className={styles.wrapper}
                        dangerouslySetInnerHTML={{
                            __html: getTextByLanguage(
                                LANGUAGES.FR,
                                "privacyPolicyText"
                            ),
                        }}
                    />
                </CenteredSection>
            </Section>
        </>
    );
};

export default PrivacyPolicy;
