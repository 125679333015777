const texts = {
    title: "Aspekty",
    welcomePanel: "Aspekty",
    welcomePanelSubtitle: `Badaj różne <strong>ASPEKTY</strong> wypowiedzi.<br />Skorzystaj z naszych narzędzi do analizy tekstu.<br />Oszczędzaj czas na raportowaniu badań marketingowych i sporządzaniu dokumentacji kadrowej`,

    aboutUsTitle: "O nas",
    aboutUsDescription: `Ewa Gwiazdecka i Anna Kot - dawno temu poznałyśmy się na treningach karatedo doshinkan.<br />Ewa  - miała dość instytucjonalnych ograniczeń, nie odnajdywała się na uniwersytecie i chciała realizować własne projekty badawcze.<br />Ania - zniechęcona tworzeniem abstrakcyjnych umów, chciała swoje prawnicze doświadczenie zastosować do własnego, konkretnego biznesu.`,
    aboutUsDescription2_1: `Nasza wspólna biznesowa przygoda zaczęła się od zamówienia z Francji na polską wersję aplikacji do spotkań interaktywnych i ankiet on-line. Wkrótce zaczęły przychodzić zlecenia od dużych korporacji: zadania z lingwistyki komputerowej oraz różne projekty wielojęzyczne, które wymagały zaangażowania wielu lingwistów.
        <br />
        Spółka rozwijała się i postawiliśmy na własne narzędzia. Zaczęłyśmy współpracę z programistami i językoznawcami z różnych ośrodków badawczych.
        <br />
        Z pasji Ewy do języka powstał Z pasji Ewy do języka powstał`,
    aboutUsDescription2_2: `We współpracy z lingwistami największą zmorą okazały się rachunki. Aby uprościć procedury prawno-księgowe, Ania wpadła na pomysł aplikacji:`,

    personTitle_1: "Ewa Gwiazdecka,<br />językoznawczyni",
    personDescription_1: `Zajmuje się lingwistyką komputerową. Obroniła doktorat na Uniwersytecie Paryż-Sorbona. Wykładała językoznawstwo na Uniwersytecie Warszawskim. Jako absolwentka filozofii wykładała również logikę i metodologię nauk na Uniwersytecie Południowej Afryki (UNISA). Obecnie studentka SGGW na Wydziale Ogrodniczym. Pasjonatka podróży, tańca afrykańskiego i musu czekoladowego.`,
    personTitle_2: "Anna Kot,<br />radca prawny",
    personDescription_2: `Absolwentka Wydziału Prawa i Administracji Uniwersytetu Warszawskiego. Po latach pracy w korporacji Miller, Canfield, Paddock & Stone w 2005 roku otworzyła własną praktykę. Specjalizuje się w prawie własności intelektualnej i nowych technologii. Wielbicielka kotów, a w szczególności trzech, które ją wybrały. Kocha spacery po łąkach i lasach. Uwielbia pływanie wpław i na wszelkich jednostkach pływających bez silnika.`,

    starcatTitle: "StarCat",
    starcatDescription: `
        <p><strong>Automatyczna, jakościowa analiza postów internetowych lub Twoich danych w przejrzystym raporcie</strong></p>
        <p>Internet czasem wygląda jak wielki śmietnik. Wbijasz hasło w wyszukiwarkę, a tylko mała część wyników do czegoś się nadaje. Wbijasz więc nowe i nowe wyrazy, żeby trafić na to, czego szukasz… Ale szukanie i porządkowanie na własną rękę bywa naprawdę męczące.</p>
        <p>Nad tym chaosem pomoże Ci zapanować <strong>StarCat</strong>.</p>
        <p><strong>StarCat</strong> jest <i>trochę innym</i> narzędziem do badania kategorii, marki, produktu lub wizerunku. Opiera się na regułach przetwarzania języka i jest naszym autorskim rozwiązaniem.</p>
        <><strong>StarCat</strong> dostarczy Ci usystematyzowaną wiedzę, dzięki której z wielu wypowiedzi dowiesz się, co ludzi trapi, co ich kręci, jakie są trendy i mody. Przekonasz się, jakie cechy Twojego produktu, usługi są ważne dla konsumentów.<p/>
        <p>
            <strong>StarCat</strong> z ogromnej ilości danych tekstowych wydobywa <strong>wątki tematyczne</strong> oraz przypisane tym wątkom <strong>opinie</strong>, które klasyfikuje ze względu na <strong>wydźwięk</strong>.<br/>
            Ale podział na opinie <i>pozytywne, negatywne i neutralne</i> to nie wszystko: <strong>StarCat</strong> potrafi <strong>uporządkować opinie</strong> pokazując ich natężenie, poda też <strong>określenia wątków</strong>, które nie są ocenne, ale mogą być ważne dla konsumentów.
        </p>
        <p><strong>Wątki tematyczne</strong> to <i>tematy</i> obecne w tekstach, to coś, o czym się właśnie mówi. Czasem frazy nieoczywiste i nieoczekiwane, które mogą Cię zaskoczyć w kontekście marki lub kategorii. </p>
        <p>Możesz zlecić nam zebranie postów z Internetu lub zamówić analizę własnych treści.</p>
        <p>Zamów intuicyjny, klikalny raport <strong>StarCat</strong>, raport skrojony na Twoją miarę.</p>
        <p><strong><i>Bo ciekawe jest to, na co Ty nie wpadłbyś sam...</i></strong></p>
    `,

    zeroTitle: "Zer0 RACHUNKÓW",
    zeroDescription: `
        <p>Czy wiesz, że zgodnie z prawem zleceniobiorca nie musi wystawiać papierowych rachunków?</p>
        <p>Zleceniobiorca, a w praktyce biuro księgowe, obsługujące Twoją firmę, nie ma obowiązku wystawiania rachunku w celu potwierdzenia wypłaty wynagrodzenia.</p>
        <p>Rachunek papierowy wcale nie musi służyć też do <strong> ewidencjonowania czasu pracy</strong> zleceniobiorcy.</p>
        <p><strong>Zamiast więc płacić biuru rachunkowemu</strong>za wystawianie rachunków, zamiast pilnować zleceniobiorców, żeby je odebrali, podpisali i odesłali, skorzystaj z naszej aplikacji <strong>Zer0 Rachunków</strong>.</p>
        <p>Jak działa <strong> Zer0 Rachunków</strong>?</p>
        <p>Zleceniobiorca po przepracowaniu okresu rozliczeniowego, loguje się do aplikacji za pomocą swojego adresu e-mail i ustanowionego przez siebie hasła, po czym akceptuje wprowadzoną przez Twoją firmę liczbę godzin pracy.</p>
        <p>Na potrzeby <strong>kontroli inspekcji pracy lub urzędu skarbowego, Zer0 Rachunków</strong> wygeneruje raporty, w których znajdą się potwierdzenia czasów pracy wszystkich lub wybranych zleceniobiorców w dowolnym okresie.</p>
        <p>Postaw na oszczędność, ekologię i wygodę!</p>
        <p>Pobierz wzór postanowień umowy-zlecenia, który umożliwi Ci zmianę ewidencjonowania czasu pracy w <strong>Zer0 Rachunków</strong>.</p>
    `,

    contactTitle: "Kontakt",
    contactEmail: "adres e-mail",
    contactMessage: "wiadomość",
    contactAction: "wyślij",
    contactSuccessTitle: "Wiadomość została wysłana",
    contactSuccessDescription: "Wiadomość została wysłana",
    contactErrorTitle: "Ups, coś poszło nie tak...",
    contactErrorDescription: "Coś poszło nie tak...",
    contactEmailFormatError: "Podaj prawidłowy adres email",
    contactEmptyError: "Pole nie może być puste",

    footerAuthor: "Aspekty",
    footerPolitics: "Polityka prywatności",

    privacyPolicyTitle: "Polityka prywatności",
    privacyPolicyText: `
        <h4>Polityka prywatności obowiązująca w Aspekty Sp. z o.o. z siedzibą w Warszawie, ul. Pokrzywnicka 5, 04-320 Warszawa (dalej zwaną „Aspekty”)</h4>
        <p>
            Niniejsza polityka prywatności (zwana dalej „Polityką”) opisuje, w jaki sposób pozyskujemy, przetwarzamy i chronimy dane osobowe osób odwiedzających naszą
            stronę internetową, osób które zdecydują się napisać e-mail na nasz adres podany w kontakcie naszej strony internetowej, osób które zamówią u nas usługi.
            Niniejsza Polityka obowiązuje wszystkie osoby odwiedzające naszą stronę internetową pod adresem <a href="www.aspekty.com">www.aspekty.com</a>.
        </p>
        <h4>Dane administratora</h4>
        <p>Administratorem Państwa danych osobowych będzie spółka Aspekty Sp. z o.o. z siedzibą w Warszawie, przy ulicy Pokrzywnickiej 5, 04-320 Warszawa</p>
        <h5>Informacja o plikach cookies</h5>
        <ol>
            <li>Na naszej stronie internetowej zbieramy i przetwarzamy dane osobowe wyłącznie przy wykorzystaniu z plików cookies.</li>
            <li>
                Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym użytkownika
                strony internetowej i przeznaczone są do korzystania z niej. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania
                ich na urządzeniu końcowym oraz unikalny numer.
            </li>
            <li>
                Podmiotem zamieszczającym na urządzeniu końcowym użytkownika naszej strony internetowej pliki cookies
                oraz uzyskującym do nich dostęp jest operator strony internetowej.
            </li>
            <li>
                Pliki cookies wykorzystywane są w celu monitorowania ruchu na naszej stronie internetowej,
                w szczególności monitorują liczbę odwiedzających oraz ścieżki ruchu.
            </li>
            <li>
                Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu
                końcowym użytkownika. Użytkownicy naszej strony internetowej mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików cookies.
                Możliwe jest także automatyczne blokowanie plików cookies. Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.
            </li>
            <li>
                Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na naszej stronie internetowej.
            </li>
            <li>
                Pliki cookies zamieszczane w urządzeniu końcowym użytkownika naszej strony internetowej wykorzystywane mogą być również przez współpracujące z operatorem strony podmioty,
                w szczególności dotyczy to firm: Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w USA), Twitter (Twitter Inc. z siedzibą w USA).
            </li>
        </ol>
        <h5>Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?</h5>
        <ol>
            <li>
                Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia swojej przeglądarki. Z
                astrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa, może utrudnić, a
                w skrajnych przypadkach może uniemożliwić korzystanie z naszej strony internetowej.
            </li>
            <li>
                Większość przeglądarek internetowych umożliwia akceptowanie lub blokowanie konkretnych plików cookies lub ich rodzajów,
                a także blokowanie lub usuwanie wszystkich plików cookies. Zależy to od danej przeglądarki internetowej.
                Aby zarządzać ustawieniami cookies, należy zapoznać się z instrukcją obsługi danej przeglądarki internetowej, której Państwo używacie i postępować zgodnie z nimi.
            </li>
        </ol>
        <h4>Jak przetwarzamy i chronimy dane osobowe zawarte w e-mailu wysłanym na nasz adres e-mailowy podany w kontakcie strony</h4>
        <h5>Zgoda na przetwarzanie danych</h5>
        <p>
            Jeżeli wyślą do nas Państwo e-mail na nasz adres <a href="mailto:kontakt@aspekty.com">kontakt@aspekty.com</a>,
            tym samym wyrażają Państwo zgodę na przetwarzanie zawartych w tym e-mailu danych osobowych, w tym adresu e-mail, z którego otrzymamy od Państwa e-maila.
        </p>
        <h5>Cele przetwarzania danych</h5>
        <p>
            Tak pozyskane dane osobowe wykorzystujemy w celu odpowiedzi na Państwa e-mail, a jeśli tą drogą zamówią Państwo u nas usługę,
            to w celu realizacji zamówionej usługi, zafakturowania wynagrodzenia za nią i pobrania zafakturowanej opłaty.
        </p>
        <p>
            Możemy też być zobowiązani na podstawie bezwzględnie obowiązujących przepisów prawa do udostępnienia Państwa danych osobowych uprawnionym organom
            publicznoprawnym. Wtedy Państwa dane zostaną udostępnione bez Państwa zgody i wiedzy i bez uprzedniego powiadomienia Państwa o tym.
        </p>
        <h5>Podstawa prawna przetwarzania danych</h5>
        <p>
            Państwa dane osobowe będą przetwarzane na podstawie art. 6 ust. 1 lit. (a) ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 roku,
            tj. na podstawie Państwa zgody wyrażonej poprzez przesłanie nam e-maila na nasz adres podany w kontakcie strony internetowej
            a jeśli będziemy realizować dla Państwa usługę, co oznaczać będzie zawarcie przez Państwa z naszą firmą umowy o wykonanie tej usługi,
            to Państwa dane będą też przetwarzane na podstawie art. 6 ust. 1 lit. (b) wyżej powołanego rozporządzenia.
        </p>
        <h5>Okres przechowywania danych</h5>
        <p>
            Dane będziemy przechowywać tak długo jak wyrażacie Państwo na to zgodę a w razie zamówienia usługi,
            czyli zawarcia z nasza firmą umowy o jej wykonanie, do czasu niezbędnego dla celów archiwizacji danych, rachunkowości,
            podatkowych, oraz innych celów niezbędnych dla przechowywania danych z umów cywilnoprawnych oświadczenie usług,
            przewidzianych bezwzględnie obowiązującymi przepisami prawa polskiego.
        </p>
        <h5>Komu udostępniamy dane</h5>
        <p>
            Państwa dane osobowe mogą być przekazywane naszym pracownikom i współpracownikom, podmiotom przetwarzającym je na zlecenie naszej firmy,
            w szczególności podwykonawcom świadczonych dla Państwa usług, biurom rachunkowym każdorazowo obsługującym naszą firmę, podmiotom udostępniającym systemy informatyczne,
            z których każdorazowo korzystać będzie nasza firma i innym podmiotom każdorazowo wspomagającym obsługę prawno – organizacyjną naszej firmy
            oraz organom lub podmiotom publicznym uprawnionym do uzyskania danych na podstawie obowiązujących przepisów prawa, np. sądom, organom ścigania lub instytucjom państwowym,
            gdy wystąpią z żądaniem, w oparciu o stosowną podstawę prawną.
        </p>
        <h5>Środki ochrony danych</h5>
        <p>
            Państwa dane osobowe są chronione poprzez stosowanie przez nas takich rozwiązań jak szyfrowanie naszej poczty elektronicznej,
            ochronę antywirusową oraz kontrolę dostępu do nich naszych pracowników i odpowiednie umowy z naszymi współpracownikami i podwykonawcami,
            na mocy których gwarantują oni bezpieczeństwo przetwarzania przekazanych im danych.
        </p>
        <h4>Uprawnienia użytkowników</h4>
        <p>
            Mają Państwo prawo do usunięcia lub aktualizacji podanych nam danych osobowych. Jeżeli uważają Państwo, że Państwa dane osobowe, które nam podaliście wymagają aktualizacji,
            bo są niepełne, niepoprawne, niewłaściwe lub nieaktualne, proszę nas o tym powiadomić na adres <a to="mailto:kontakt@aspekty.com">kontakt@aspekty.com</a>,
            a my wprowadzimy niezbędne poprawki lub usuniemy nieprawidłowe dane w okresie przewidzianym przez obowiązujące przepisy.
            Mają Państwo prawo do informacji, jakie dotyczące Państwa dane osobowe zgromadziliśmy, prawo zażądania od nas ograniczenia przetwarzania Państwa danych osobowych,
            prawo wniesienia sprzeciwu wobec ich przetwarzania, a także prawo do przenoszenia swoich danych.
        </p>
        <p>
            Mogą też Państwo skorzystać z prawa do wniesienia skargi do Prezesa UODO gdy uznają Państwo, że przetwarzanie przez nas Państwa danych osobowych narusza przepisy
            ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 roku. Mają Państwo także prawo zażądać od nas, abyśmy zaprzestali przetwarzania Państwa
            danych osobowych. Jednak w przypadku rezygnacji tj. cofnięcia zgody na przetwarzanie przez nas Państwa danych lub zażądania od nas zaprzestania przetwarzania
            Państwa danych osobowych, może się okazać, że nie będziemy mogli wykonać zamówionej przez Państwu usługi lub odpowiadać na Państwa e-maile.
            Ponadto cofnięcie zgody pozostaje bez wpływu na zgodność z prawem przetwarzania, którego dokonaliśmy na podstawie zgody przed jej cofnięciem.
        </p>
    `,
};

export default texts;
